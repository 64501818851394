import { Outlet, Link, useLocation } from "react-router-dom";
import { Box, Typography, useTheme, Button, useMediaQuery, Menu, MenuItem } from "@mui/material";
import Logo from "../Images/FullLogo_Transparent_NoBuffer.png";
import { common } from "@mui/material/colors";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';

export default function Root() {
  const theme = useTheme();
  const location = useLocation();
  const handlePhoneLinkClick = () => {
    window.location.href = `tel:+1305-965-4742`;
  };
  const handleEmailLinkClick = () => {
    window.location.href = `mailto:reservations@miamiftlauderdaleshuttle.com`;
  };
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box sx={{ minHeight: "100%" }} display="flex" flexDirection="column" id="splash-image">
      <Box
        display={"flex"}
        p={2}
        sx={{
          backgroundColor: "#0C1C2C",
          color: theme.palette.common.white,
        }}
        alignItems={"center"}
        flexDirection={isMobile ? "column" : "row"}
      >
        <Box width={50}/>
        <Button style={{ color: 'white' }} onClick={handlePhoneLinkClick}>
          <Typography fontFamily={"sans"}><LocalPhoneIcon fontSize="small" style={{ position: "relative", top: "3px" }}></LocalPhoneIcon> +1 (305) 965 - 4742 </Typography></Button>
        <Box width={50}/>
        <Button style={{ color: 'white' }} onClick={handleEmailLinkClick}>
          <Typography fontFamily={"sans"}><EmailIcon fontSize="small" style={{ position: "relative", top: "3px" }}></EmailIcon>  Reservations@miamiftlauderdaleshuttle.com </Typography>
        </Button>
        <Box width={50}/>
      </Box>
      <Box display="flex" alignItems="center" px={2} flexDirection={"column"} mt={2}>
        <Link to="">
          <img src={Logo} height="250px" width="auto" />
        </Link>
        <Box display="flex" alignItems="center" px={2} flexDirection={"row"} mt={3}>
          <Link to="book">
            <Typography
              variant="h6"
              sx={{
                "&:hover": { color: 'silver' },
                transition: "color 0.5s",
                color:
                  location?.pathname === "/book"
                    ? 'silver'
                    : '#F7D5A3',
              }}
              ml={4}
              fontWeight={"Bold"}
              fontFamily={"sans"}
            >
              Book Now
            </Typography>
          </Link>
          <Link to="fleet">
            <Typography
              variant="h6"
              sx={{
                "&:hover": { color: 'silver' },
                transition: "color 0.5s",
                color:
                  location?.pathname === "/fleet"
                    ? 'silver'
                    : '#F7D5A3',
              }}
              ml={4}
              fontWeight={"bold"}
              fontFamily={"sans"}
            >
              Our Fleet
            </Typography>
          </Link>
          <Link to="about">
            <Typography
              variant="h6"
              sx={{
                "&:hover": { color: 'silver' },
                transition: "color 0.5s",
                color:
                  location?.pathname === "/about"
                    ? 'silver'
                    : '#F7D5A3',
              }}
              ml={4}
              fontWeight={"Bold"}
              fontFamily={"sans"}
            >
              About Us
            </Typography>
          </Link>
        </Box>
      </Box>
      <Box flex={1}>
        <Outlet />
      </Box>
    </Box>
  );
}
