import { Box, Typography, useTheme, Button } from "@mui/material";
import { Link } from "react-router-dom";

export default function AboutPage() {
  const theme = useTheme();
  return (
    <Box mt={5}>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
      >
        <Typography variant="h4" fontWeight={"bold"} color="#F7D5A3" marginBottom={0}>About Us</Typography>
        &nbsp;
      </Box>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        sx={{ maxWidth: "800px", marginLeft: "auto", marginRight: "auto", marginTop: 1, marginBottom: 5 }}
        color={'#bec2cb'}
      >
      <Typography variant="body1" paragraph>
        <strong>Miami Fort Lauderdale Shuttle</strong> is a premium transportation provider specializing in convenient, reliable transfers between major airports, cruise ports, and private residences in South Florida. Our services cater to a wide range of passengers, from airport and cruise transfers to executive car services, ensuring a seamless travel experience.
      </Typography>

      <Typography variant="body1" paragraph>
        We operate efficiently between <strong>Miami International Airport (MIA)</strong>, <strong>Fort Lauderdale-Hollywood International Airport (FLL)</strong>, <strong>Port of Miami (POM)</strong>, and <strong>Port Everglades (Fort Lauderdale)</strong>, making it easy for travelers heading to or from cruises, airports, hotels, or private destinations.
      </Typography>

      <Typography variant="h5" component="h2" gutterBottom color="#F7D5A3">
        Key Features of Our Services:
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Airport Transfers:</strong> Fast, dependable shuttle services to and from Miami and Fort Lauderdale airports, ensuring timely arrivals and departures.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Cruise Transfers:</strong> Specialized transportation to cruise terminals, perfect for passengers traveling to or from their cruise ship.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Private & Executive Transfers:</strong> Comfortable, private transfers with flexible booking options tailored to your schedule.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Affordability:</strong> Competitive pricing that offers great value without compromising on service quality.
      </Typography>

      <Typography variant="h5" component="h2" gutterBottom color="#F7D5A3">
        Comprehensive Miami and Fort Lauderdale Transportation Services
      </Typography>

      <Typography variant="body1" paragraph>
        Beyond standard airport and cruise port shuttles, <strong>Miami Fort Lauderdale Shuttle</strong> provides a range of tailored services designed for various travel needs:
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Airport Transfers:</strong> Offering reliable and timely rides to Miami International Airport (MIA) and Fort Lauderdale-Hollywood International Airport (FLL).
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Port Transfers:</strong> Direct and hassle-free transportation to and from cruise terminals in Miami and Fort Lauderdale.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Special Events Transportation:</strong> Personalized transport for special events such as concerts, conferences, and private parties, ensuring guests arrive comfortably and punctually.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Private Sightseeing Tours:</strong> Custom tours of Miami and Fort Lauderdale&apos;s top attractions, with the flexibility of a private vehicle for a personalized experience.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Wedding Transportation:</strong> Dedicated services for wedding events, providing seamless transport for guests between venues and receptions.
      </Typography>

      <Typography variant="body1" paragraph>
        Our services are designed to deliver <strong>comfort</strong>, <strong>convenience</strong>, and a <strong>personalized experience</strong> for every traveler, whether you&apos;re in South Florida for leisure, a special occasion, or routine travel.
      </Typography>
      </Box>
    </Box>
  );
}