import { Box, Typography, useTheme, Button, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import { useState, useMemo, useCallback } from "react";
import Information from "./Information";
import { InfoState} from "./types";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';

const initialInfoState: InfoState = {
  pickupLocation: "",
  dropoffLocation: "",
  passengerCount: null,
  price: null,
};
const handlePhoneLinkClick = () => {
  window.location.href = `tel:+1305-965-4742`;
};
const handleEmailLinkClick = () => {
  window.location.href = `mailto:reservations@miamiftlauderdaleshuttle.com`;
};
export default function HomePage() {
  const [infoState, setInfoState] = useState(initialInfoState);
  const [priceError, setPriceError] = useState<string>();
  const [priceLoading, setPriceLoading] = useState(false);
  const [locationFocused, setLocationFocused] = useState(false);

  const updateInfoState = useCallback(
    <T extends keyof InfoState>(key: T, value: InfoState[T]) => {
      setInfoState({ ...infoState, [key]: value });
    },
    [infoState]
  );
  const informationErrors = useMemo(() => {
    const errors = {} as Record<string, string>;
    if (priceError) errors.price = priceError;
    return errors;
  }, [priceError]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        sx={{ maxWidth: "800px", marginLeft: "auto", marginRight: "auto", marginTop: 5, marginBottom: 5, display: 'none'}}
        overflow="auto"
      >
        <Typography variant="h6" color="common.white">Miami Airport, Port of Miami, Fort Lauderdale Airport, Miami Hotel transportation, South Beach transportation, Miami cruise transportation </Typography>
        <Typography variant="h2" component="h1" gutterBottom>
        Miami Fort Lauderdale Shuttle Service
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>Miami Fort Lauderdale Shuttle</strong> is a premium transportation provider specializing in convenient, reliable transfers between major airports, cruise ports, and private residences in South Florida. Our services cater to a wide range of passengers, from airport and cruise transfers to executive car services, ensuring a seamless travel experience.
        </Typography>

        <Typography variant="body1" paragraph>
          We operate efficiently between <strong>Miami International Airport (MIA)</strong>, <strong>Fort Lauderdale-Hollywood International Airport (FLL)</strong>, <strong>Port of Miami (POM)</strong>, and <strong>Port Everglades (Fort Lauderdale)</strong>, making it easy for travelers heading to or from cruises, airports, hotels, or private destinations.
        </Typography>

        <Typography variant="h4" component="h2" gutterBottom>
          Key Features of Our Services:
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>Airport Transfers:</strong> Fast, dependable shuttle services to and from Miami and Fort Lauderdale airports, ensuring timely arrivals and departures.
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>Cruise Transfers:</strong> Specialized transportation to cruise terminals, perfect for passengers traveling to or from their cruise ship.
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>Private & Executive Transfers:</strong> Comfortable, private transfers with flexible booking options tailored to your schedule.
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>Affordability:</strong> Competitive pricing that offers great value without compromising on service quality.
        </Typography>

        <Typography variant="h4" component="h2" gutterBottom>
          Comprehensive Miami and Fort Lauderdale Transportation Services
        </Typography>

        <Typography variant="body1" paragraph>
          Beyond standard airport and cruise port shuttles, <strong>Miami Fort Lauderdale Shuttle</strong> provides a range of tailored services designed for various travel needs:
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>Airport Transfers:</strong> Offering reliable and timely rides to Miami International Airport (MIA) and Fort Lauderdale-Hollywood International Airport (FLL).
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>Port Transfers:</strong> Direct and hassle-free transportation to and from cruise terminals in Miami and Fort Lauderdale.
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>Special Events Transportation:</strong> Personalized transport for special events such as concerts, conferences, and private parties, ensuring guests arrive comfortably and punctually.
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>Private Sightseeing Tours:</strong> Custom tours of Miami and Fort Lauderdale&apos;s top attractions, with the flexibility of a private vehicle for a personalized experience.
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>Wedding Transportation:</strong> Dedicated services for wedding events, providing seamless transport for guests between venues and receptions.
        </Typography>

        <Typography variant="body1" paragraph>
          Our services are designed to deliver <strong>comfort</strong>, <strong>convenience</strong>, and a <strong>personalized experience</strong> for every traveler, whether you&apos;re in South Florida for leisure, a special occasion, or routine travel.
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        minHeight="50vh"
        overflow="auto"
      >
        <Information
          isMobile={isMobile}
          updateInfoState={updateInfoState}
          setPriceError={setPriceError}
          infoState={infoState}
          errors={informationErrors}
          setPriceLoading={setPriceLoading}
          setLocationFocused={setLocationFocused}
        />
        <Box display="flex" justifyContent="center" padding={3}>
          <Button variant="contained" style={{ backgroundColor: '#F7D5A3' }} onClick={handlePhoneLinkClick}>
            <Typography fontFamily={"sans"} color={"#0C1C2C"}>Call Now <LocalPhoneIcon fontSize="small" style={{ position: "relative", top: "3px" }}></LocalPhoneIcon></Typography>
          </Button>
          <Box width={50} />
          <Button variant="contained" style={{ backgroundColor: '#F7D5A3' }} onClick={handleEmailLinkClick}>
            <Typography fontFamily={"sans"} color={"#0C1C2C"}>Email Us <EmailIcon fontSize="small" style={{ position: "relative", top: "3px" }}></EmailIcon></Typography>
          </Button>
          <Box width={50} />
          <Link to="/book">
            {infoState.pickupLocation && infoState.dropoffLocation && infoState.passengerCount && (
            <Button variant="contained" size="large" style={{ backgroundColor:"#F7D5A3"}}>
              <Typography
                p={1}
                fontFamily={"sans"}
                fontWeight={"Bold"}
                color={"#0C1C2C"}
              >
                Book Now!
              </Typography>
            </Button>
            )}
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
