import { Box, Typography, useTheme, Button } from "@mui/material";
import { Link } from "react-router-dom";
import Fleet from "../../Images/mia-fleet.jpg";
import Fleet2 from "../../Images/VanPics/carseats.jpg";
import Fleet3 from "../../Images/VanPics/esl-teampic-1.jpg";
import Fleet4 from "../../Images/VanPics/interior-1.jpg";
import Fleet5 from "../../Images/VanPics/interior-2.jpg";

export default function FleetPage() {
  const theme = useTheme();
  return (
    <Box mt={5}>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
      >
        <Typography variant="h4" fontWeight={"bold"} color="#F7D5A3" marginBottom={0}>Our Fleet</Typography>
        &nbsp;
      </Box>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        sx={{ maxWidth: "800px", marginLeft: "auto", marginRight: "auto", marginTop: 1, marginBottom: 5 }}
        color={'#bec2cb'}
      >
        <Typography variant="body1" paragraph>
          <strong>Miami Fort Lauderdale Shuttle</strong> offers a versatile fleet designed to accommodate various passenger and luggage sizes. This ensures that they can effectively meet the needs of all travelers, whether you&apos;re traveling solo with minimal baggage or in a larger group with extensive luggage.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom color="#F7D5A3">
          Key Features:
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>Diverse Vehicle Options:</strong> From sedans and SUVs to vans and shuttles, we provide the right vehicle for every group size.
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>Luggage Capacity:</strong> Our vehicles are equipped to handle different amounts of luggage, ensuring a comfortable ride without compromising space.
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>Comfortable Seating:</strong> Well-maintained vehicles that prioritize passenger comfort during transit.
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>Accessibility:</strong> We offer options to cater to travelers with special needs.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom color="#F7D5A3">
          Vehicle Specifications:
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>Vans:</strong> Mercedes Sprinter - Accommodates up to 14 passengers and 28 pieces of luggage.
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>Mercedes Metris:</strong> Accommodates up to 6 passengers and 6 pieces of luggage.
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>Executive Suburban:</strong> Accommodates up to 6 passengers and 6 pieces of luggage.
        </Typography>
        <Box display="flex" alignItems="center" px={2} flexDirection={"column"}>
          <img src={Fleet3} style={{ maxWidth: "100%", maxHeight: "800px", width: "100%", height: "auto" }}/>
          <Box height={10}/>
          <img src={Fleet} style={{ maxWidth: "100%", maxHeight: "800px", width: "100%", height: "auto"}}/>
          <Box height={10}/>
          <img src={Fleet4} style={{ maxWidth: "100%", maxHeight: "800px", width: "100%", height: "auto" }}/>
          <Box height={10}/>
          <img src={Fleet5} style={{ maxWidth: "100%", maxHeight: "800px", width: "100%", height: "auto" }}/>
          <Box height={10}/>
          <img src={Fleet2} style={{ maxWidth: "100%", maxHeight: "800px", width: "100%", height: "auto" }}/>
        </Box>
      </Box>
    </Box>
  );
}